@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  font-family: 'Prompt', sans-serif;
}
.special{
  font-family: 'Prompt', sans-serif;
}
body{
  color: #1e293b;

}
.big{
  font-size: 41.88px;
}
.medium{
  font-size: 25.8px;
}
.smallest{
  font-size: 9.88px;
}
.yellow{
  background-color: #ffefb2;
}
.brown{
  background-color: #682900;
}
.whiteText{
  color: white;
  /* opacity: 90%; */
}
